<template>
  <div class="">
 
    <div class="">
      <div class="">
        <div class="">
          <div v-if="isLoading" class="loader d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" >
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Email Templates</span>
            </div>
          </div>
          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"
          ></b-skeleton-table>
          <b-table
            row-class="custom-row-class"
            v-else-if="items.length > 0"
            style="min-height:200px"
            hover
            :responsive="true"
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            :fields="fields"
            :items="items"
            show-empty
          >
            <template #cell(design_image)="data">
              <div class="d-flex align-items-center">

                <LazyImage
                  :src="data.item.design_image"
                  alt=""
                  class="img-thumbnail"
                  style="
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                  "
                />
                <div class="">
                  {{ data.item.name }}
                </div>
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown class="" id="collection-detail">
                <template slot="button-content">
                  <div class="p-2">
                    <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </template>
                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="openEditNameModal(data.item)"
                >
                  Edit Name</b-dropdown-item
                >
                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="navigateToEditor(data.item.key)"
                >
                  Edit Design</b-dropdown-item
                >
                 <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="()=>{
                    testEmailTempId=data.item.id;
                    $refs.testEmailRef.open()}"
                >
                  Test Template </b-dropdown-item
                >
                 <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="()=>{
                    duplicateEmail.id=data.item.id;
                    duplicateEmail.name = data.item.name + ' - Copy';
                    $refs.DuplicateEmail.open()}"
                >
                  Duplicate Template</b-dropdown-item
                >
                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="openDeleteTemplateModal(data.item.id)"
                >
                  Delete</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No email flow found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
        
          <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"   v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
        </div>
      </div>
    </div>
  

    <!-- Delete Modal Start -->
    <DeleteModal  ref="deleteEmailTemplateRef"
    id="deleteEmailTemplateRef" :isLoading="isDeleteTemplateLoading" item="template" title="Delete Email Template" @click="deleteSavedEmailTemplates" ></DeleteModal>
    <!-- Delete Modal End -->
    <!-- Edit Name Modal Start  -->
    <modal
    ref="editEmailRef"
    id="editEmailRef"
    size="md"
    title="<span class='px-4'>Update E-mail Template Name</span>">
    <div class="p-4" >
        
        <div class="w-100 mb-4  " v-if="selectedTemplateToEditName">
          <div
              class="CreateEmailInput d-flex align-items-center w-100 pr-3 py-2"
            >
              <input
                type="text"
                class="mx-2 flex-grow-1"
                v-model="selectedTemplateToEditName.name"
                placeholder="Enter email template name"
              />
            </div>
                    </div>
                    <div class="text-center">
                        <Button :is-loading="isEditNameLoading" @click="updateTemplateName"
              >Update </Button
            >
                    </div>
      
    </div>
  </modal>
  <!-- Duplicate Email Modal -->
   <modal
    ref="DuplicateEmail"
    id="DuplicateEmail"
    size="md"
    title="<span class='px-4'> Duplicate Email Template </span>">
       <div class="p-4" >
        
        <div class="w-100 mb-4 ">
          <div
              class="CreateEmailInput d-flex align-items-center w-100 pr-3 py-2"
            >
              <input
                type="email"
                class="mx-2 flex-grow-1"
                v-model="duplicateEmail.name"
                placeholder="Template Name"
              />
            </div>
                    </div>
                    <div class="text-center">
                        <Button 
            @click="DuplicateEmail()" :isLoading="isLoadingCreateEmail" >Duplicate </Button
            >
                    </div>
      
      </div>
      </modal>
  <modal
    ref="testEmailRef"
    id="testEmailRef"
    size="md"
    title="<span class='px-4'> Test Email Template </span>">
       <div class="p-4" >
        
        <div class="w-100 mb-4 ">
          <div
              class="CreateEmailInput d-flex align-items-center w-100 pr-3 py-2"
            >
              <input
                type="email"
                class="mx-2 flex-grow-1"
                v-model="testingEmail"
                placeholder="Enter email"
              />
            </div>
                    </div>
                    <div class="text-center">
                        <Button 
            @click="TestEmail()" :isLoading="isLoadingCreateEmail" >Send </Button
            >
                    </div>
      
      </div>
      </modal>
    <!-- Edit Name Modal End -->
    
    <FooterContent >
<div class="d-flex align-items-center justify-content-end">
  <Button @click="navigateToCreateTemplates">+ Create Template</Button>
</div>
</FooterContent>
  </div>
</template>
<script>
import EmptyTable from "../../Layout/EmptyTable.vue";
import DeleteModal from "../../modal/DeleteModal.vue";
import { mapActions } from "vuex";
import { debounce } from "lodash";

import Button from "../../Layout/Button.vue";
import Modal from "../../Layout/Modal.vue";
import QuizellPagination from "../../Layout/QuizellPagination.vue";
import FooterContent from '../../Layout/FooterContent.vue';
export default {
  components: {
    FooterContent,
    Button,
    QuizellPagination,
    Modal,
    EmptyTable,
    DeleteModal
  },
  watch: {
    "filter.title"() {
      this.handleSearchDebounced();
    },
    allLeads({ selected, all }) {
      if (all.every((lead) => selected.includes(lead.id)) && all.length > 0)
        this.selectAllLeads = true;
      else this.selectAllLeads = false;
    },
  },
  computed: {
    allLeads() {
      return { selected: this.selectedLeads, all: this.items };
    },
  },
  data() {
    return {
      testEmailTempId:null,
      testingEmail:null,
      isVisibleUpdateEmailTemp: false,
      isUpdateEmailTemp: false,
      fetchingUpdateEmailTempLoading: false,
      isLoadingCreateEmail: false,
      isDeleteTemplateLoading:false,
      emailDetail: {},
      duplicateEmail:{
        name:'',
        id:0
      },
      isVisibleCreateEmailTempModal: false,
      selectAllLeads: false,
      filter: {
        search: "",
        fields: "id,name,key,design_image,created_at",
        per_page: 10,
        page: 1,
      },
      searchQuiz: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 20,
      randomColors: [],
      deleteLeadLoading: false,
      selectedLeads: [],
      isLoading: false,
      loadingFilter: false,
      isLoadingFirstTime: true,
      fields: [
        {
          key: "design_image",
          label: "Template",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
       

        {
          key: "created_at",
          label: "Created At",
          tdClass: "data-Table align-middle",
          thClass: "bg-table-heading",
        },
        {
          key: "actions",
          label: "",
          tdClass: "data-Table align-middle",
          thClass: "bg-table-heading",
        },
      ],
      items: [],
      selectedTemplateToDelte:null,
      selectedTemplateToEditName:null,
      isEditNameLoading:null
    };
  },
  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.getSavedEmailTemplates();
    },
    updatePagination(e){
      this.currentPage=e;
      this.getSavedEmailTemplates();
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },
    ...mapActions(["fetchData"]),

    async deleteSavedEmailTemplates() {
      try {
        this.isDeleteTemplateLoading = true

        

        const response = await this.fetchData({
                axiosMethod:'delete',
                resource:'email_templates',
                method:'delete',
                id: this.selectedTemplateToDelte
            })


            if(response.status == 'success'){
              this.selectedTemplateToDelte = null
              this.$refs.deleteEmailTemplateRef.close()
              this.getSavedEmailTemplates()
            }
        
      } catch (error) {
          if(error){
              this.$toasted.show("Error Occured!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          throw error
          }
      } finally{
        this.isDeleteTemplateLoading = false
      }
    },
    navigateToEditor(key){

      let baseUrl = window.location.origin;

      let finalURL =
        baseUrl +
        `/EmailEditor/${key}`;

      window.open(finalURL, "_self");

      // this.$router.push(`/EmailEditor/${key}`)
    },
          async DuplicateEmail() {
      this.isLoadingCreateEmail = true;
      const getEmailTemplates = await this.fetchData({
        axiosMethod: "post",
        resource: "email_templates",
        id:this.duplicateEmail.id,
        method: "duplicateEmail",
        params:{name:this.duplicateEmail.name },
      });
     
     if(getEmailTemplates)
     {
       this.$toasted.show("Email duplicated successfully .", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$refs.DuplicateEmail.close();
          this.getSavedEmailTemplates();
     }
     else
     {
       this.$toasted.show("Error while sending email", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
     }
      this.isLoadingCreateEmail = false;
    },
      async TestEmail() {
      this.isLoadingCreateEmail = true;
      const getEmailTemplates = await this.fetchData({
        axiosMethod: "post",
        resource: "email_templates",
        id:this.testEmailTempId,
        method: "testEmail",
        params:{email: this.testingEmail  },
      });
    
     if(getEmailTemplates)
     {
       this.$toasted.show("Test email has been sent.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$refs.testEmailRef.close()
     }
     else
     {
       this.$toasted.show("Error while sending email", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
     }
      this.isLoadingCreateEmail = false;
    },
    async getSavedEmailTemplates() {
      this.isLoading = true;
      const getEmailTemplates = await this.fetchData({
        axiosMethod: "get",
        resource: "email_templates",
        method: "list",
        params: { params: {...this.filter, per_page:this.perPage }},
      });

      this.items = getEmailTemplates.data.email_templates.data;
      this.totalRows = getEmailTemplates.data.email_templates.total;
      this.isLoading = false;
      this.isLoadingFirstTime = false;
    },
    checkFirstCharacter(item) {
      if (item.full_name) return item.full_name.charAt(0).toUpperCase();
      else if (item.email) return item.email.charAt(0).toUpperCase();
      return "Q";
    },

    PaginationData() {
      this.getSavedEmailTemplates();
    },
    handleSearchDebounced: debounce(function () {
      this.getSavedEmailTemplates();
    }, 500),

    navigateToCreateTemplates() {
      this.$router.push("/manage/emails/create-email-templates");
    },

    openDeleteTemplateModal(id){
      this.selectedTemplateToDelte = null
      if(id){
        this.selectedTemplateToDelte = id
        
      this.$refs.deleteEmailTemplateRef.open()
      }
    },

    openEditNameModal(template){
      this.selectedTemplateToEditName = null

      if(template){
        this.selectedTemplateToEditName = {...template}
        this.$refs.editEmailRef.open()
      }
    },
   async updateTemplateName(){
    if(this.selectedTemplateToEditName.name == ''){
      this.$toasted.show("Name is required!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          return true
    }

    try {
      this.isEditNameLoading = true

      const response = await this.fetchData({
        axiosMethod: "put",
        resource: "email_templates",
        method: "update",
        id:this.selectedTemplateToEditName.id,
        params: this.selectedTemplateToEditName ,
      });

      if(response.status == 'success'){
        this.$toasted.show("Name successfully updated!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        
        this.selectedTemplateToEditName = null

        this.$refs.editEmailRef.close()
        this.getSavedEmailTemplates()
      }

      
    } catch (error) {
        if(error){
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error
        }
    } finally{
      this.isEditNameLoading = false
    }
   }
  },

  async created() {
    await this.getSavedEmailTemplates();
  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}
.q-collapse::after {
  content: "";
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 1px;
  border: 1px solid rgb(206 206 210 / 25%);
}
.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor: pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  color: #3f1768;
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}

.delteTitle{
  color: #18191C;
font-family: Poppins;
font-size: 20px;
font-style: normal;
text-align: center;
font-weight: 600;
line-height: normal;
letter-spacing: 0.56px;
text-transform: capitalize;
}
.deleteSubtext{
  color: #73738D;
font-family: Poppins;
font-size: 14px;
font-style: normal;
text-align: center;
font-weight: 400;
line-height: 100%; /* 14px */
}

.CreateEmailInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.CreateEmailInput input {
  background: transparent;
  border: none;
  outline: none;
}
.CreateEmailInput input:focus {
  border: none;
  outline: none;
}
</style>
